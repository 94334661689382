import React, {useContext, useState, useEffect} from 'react'
import {Button, Spacer, Text, Link as NextLink} from "@nextui-org/react";
import { useNavigate, useLocation } from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import {useForm} from "../hooks/useForm";
import {AuthContext} from "../context";
import { FaFacebookSquare, FaGoogle } from 'react-icons/fa';
import { Header } from "../layout/header";
import { Footer } from "../layout/footer";
import axios from 'axios';

function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { setErrors, renderFieldError, message, setMessage } = useForm();
    const { search } = useLocation();
    const errorMessage = new URLSearchParams(search).get('errorMessage') ?? ''

    const {setAsLogged, handleResponse} = useAuth();
    const {authData} = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        // console.log('Login useEffect->',authData)
        if(authData.signedIn) {
            navigate('/');
        }
    }, [authData]);
    useEffect(() => {
        setMessage(errorMessage)
    }, []);

    const makeRequest = () => {
        setErrors(null);

        setMessage('');
        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': '*',
                'Content-Type': 'application/json' },
            mode: 'no-cors',
            body: JSON.stringify({ email, password })
        };
        axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password })
            .then(handleResponse)
            .then((user) => {

                if(user.token) {
                    setAsLogged(user);
                }

                return user;
            }).catch(error => {
                error = error?.response?.data
                console.error(error);
                if(error.message) {
                    setMessage(error.message);
                }
                if (error.data) {
                    setErrors(error.data);
                }
            });
        return
        return fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions)
            .then(handleResponse)
            .then(({data}) => {
                const user = data
                if(user.token) {
                    setAsLogged(user);
                }

                return user;
            }).catch(error => {
                console.error(error);
                if(error.message) {
                    setMessage(error.message);
                }
                if (error.data) {
                    setErrors(error.data);
                }
            });

    };

    return (
        <div className='flex flex-col items-center justify-between w-screen min-h-screen bg-[#0a0a0a]'>
            <Header/>
            <div className="flex flex-col h-full w-11/12 md:w-10/12 items-center justify-center ">
                <div id='websiteNameDiv' className="flex flex-col w-11/12 md:w-4/6 items-center justify-center">
                    <div className='items-center justify-center transition flex flex-col items-start w-11/12 lg:w-2/3 bg-[#111111] rounded-xl border border-[#3f3f3f] hover:bg-[#181818] p-5'>
                        <h1 className="font-bold text-2xl text-white">Sign In</h1>
                        <NextLink href={`${process.env.REACT_APP_API_URL}/auth/google`}>
                            <div className='flex flex-row items-center justify-center gap-x-5'>
                                <Button auto size={'sm'}  className='mt-4'>Sign In / Sign Up <FaGoogle className='ml-1' /> </Button>
                            </div>
                        </NextLink>
                        <NextLink href={`${process.env.REACT_APP_API_URL}/auth/facebook`}>
                            <div className='flex flex-row items-center justify-center gap-x-5'>
                                <Button auto size={'sm'}  className='mt-4'>Sign In / Sign Up <FaFacebookSquare className='ml-1' /> </Button>
                            </div>
                        </NextLink>
                        <Spacer x={1} />

                        <div className='flex flex-row items-center justify-start gap-x-2 mb-3'>
                            <input onChange={(e) => setEmail(e.target.value)} type='email' value={email} className='cursor-pointer bg-white/20 border border-white/60 rounded-xl px-3 py-2 text-white w-56'  placeholder='Enter email' />
                        </div>
                        <div className='flex flex-row items-center justify-start gap-x-2 mb-3'>
                            <input  onChange={(e) => setPassword(e.target.value)} type='password' value={password} className='cursor-pointer bg-white/20 border border-white/60 rounded-xl px-3 py-2 text-white w-56'  placeholder='Enter Password' />
                        </div>
                        {
                            message && <Text color="error">{message}</Text>
                        }

                        { renderFieldError('password') }
                        <div className='flex flex-row items-center justify-center gap-x-5'>
                            <Button onPress={makeRequest} auto size={'sm'} className='mt-4'>Sign In</Button>
                        </div>
                        <div className='flex flex-row items-center justify-center gap-x-5'>
                            <Button onPress={()=>navigate('/signup')} auto size={'sm'} className='mt-4'>Sign Up</Button>
                        </div>

                    </div>
                </div>
            </div>
            {/* Footer */}
            <Footer/>
        </div>
    )
}

export default Login