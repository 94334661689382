import React from "react";
import { Button } from "@nextui-org/react";
import { Spacer } from "@nextui-org/react";
import { Text } from "@nextui-org/react";
import jsPDF from 'jspdf';
import { useNavigate  } from 'react-router-dom';
import { Configuration, OpenAIApi } from "openai";
import { BsCheck } from "react-icons/bs";
import {RxDotFilled} from 'react-icons/rx';
import { Header } from "../layout/header";

function Tool() {
    const navigate = useNavigate();
    const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_KEY
    });
    const openai = new OpenAIApi(configuration);

    const [idea, setIdea] = React.useState();
    const [headings, setHeadings] = React.useState([])
    const [websiteName, setWebsiteName] = React.useState([]);
    const [colors, setColors] = React.useState([]);
    const [typography, setTypography] = React.useState([]);
    const [placement, setPlacement] = React.useState([]);
    const [form, setForm] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [colorHex, setcolorHex] = React.useState([]);
    const [customcolor, setCustomcolor] = React.useState("");

    const [business, setBusiness] = React.useState([]);
    const [showBusinessPlan, setShowBusinessPlan] = React.useState(false);

    const [showNameTick, setShowNameTick] = React.useState(false)
    const [webDesignTick, setShowWebDesignTick] = React.useState(false);
    const [showBusPlanTick, setShowBusPlanTick] = React.useState(false);
    const [showPrdTick, setShowPrdTick] = React.useState(false);

    const [headingCopy, setHeadingCopy] = React.useState(false);
    const [colorsCopy, setColorsCopy] = React.useState(false);
    const [typographyCopy, setTypographyCopy] = React.useState(false);
    const [placementCopy, setPlacementCopy] = React.useState(false);
    const [formCopy, setFormCopy] = React.useState(false);
    const [allResults, setAllResults] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState("");

    const [showOptions, setShowOptions] = React.useState(false);
    const [showHeadings, setShowHeadings] = React.useState(false);
    const [showWebsiteName, setShowWebsiteName] = React.useState(false);
    const [showColors, setShowColors] = React.useState(false);
    const [showTypography, setShowTypography] = React.useState(false);
    const [showPlacement, setShowPlacement] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);
    const [showRestart, setShowRestart] = React.useState(false);
    const [showInitialLayouyt, setShowInitialLayout] = React.useState(true);
    const [showPrd, setShowPrd] = React.useState(false);
    const [showWebsiteDesignSummary, setShowWebsiteDesignSummary] = React.useState(false);
    const [showBusinessPlanSummary, setShowBusinessPlanSummary] = React.useState(false);

    const [showGenerateDesignBtn, setShowGenerateDesignBtn] = React.useState(true);
    const [showGenerateBusinessPlanBtn, setShowGenerateBusinessPlanBtn] = React.useState(true);
    const [productRequirementsCopy, setProductRequirementsCopy] = React.useState([]);

    const onCustomClr = (e) => {
        setCustomcolor(e.target.value);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleCustomColour = (event) => {
        setCustomcolor(event.target.value);
    };
    const handleCustomNameChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const resetOption = () => {
        setSelectedOption("");
    };

    const onChange = (e) => {
        setIdea(e.target.value);
    };

    const generateHeadings = async () => {
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `Generate Website Headings for this Website Idea : ${idea}. Dont add list counting numbers at start of headings. Add -1 at end of each line. Dont add "" or '' or any other character. Only add -1 at end of each line.`,
            temperature: 0.9,
            max_tokens: 1200,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 1.0,
        });
        // push data to new array and split it by -1 and replace \n with ""
        const headings = response.data.choices[0].text.split("-1").map((item) => item.replace(/\n/g, ""));
        // remove last empty entry
        headings.pop();
        setHeadings(headings);
    };

    const generateWebsiteName = async () => {
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `Generate like this Name1-1Name2-1Name3-1 etc. Dont add "" or '' or any other character. Only add -1 at end of each line.Also dont add any kind of numbers count at start of each name.
            Generate 7 Brand Names for this Website Idea : ${idea}. Generate Unique and Easy to remember names.`,
            temperature: 0.9,
            max_tokens: 1200,
            best_of: 3,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 1.0,
        });
        // push data to new array and split it by -1 and replace \n with ""
        const websiteName = response.data.choices[0].text.split("-1").map((item) => item.replace(/\n/g, ""));
        // remove last empty entry
        websiteName.pop();
        setWebsiteName(websiteName);
    };

    const generateColors = async () => {
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `Generate Website Colors (according to the theme that suits this product idea : ${idea} best) (total 5) (i.e primary, secondary, text color, muted color, neutral color). Add -1 at end of each line. Dont add " " or ' ' or any other character. Only add -1 at end of each line. Generate results like this (primary color: #000000) (secondary color: #000000) (text color: #000000) (muted color: #000000) (neutral color: #000000).`,
            temperature: 0.2,
            max_tokens: 1200,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
        });
        // push data to new array and split it by -1 and replace \n with ""
        const colors = response.data.choices[0].text.split("-1").map((item) => item.replace(/\n/g, ""));
        // remove last empty entry
        colors.pop();
        setColors(colors);

        // // get hex values from colors array
        const colorHex = colors.map((item) => item.split(":")[1].trim());
        setcolorHex(colorHex);
    };

    const generateCustomColors = async () => {
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `Generate Website Colors (according to the ${customcolor} theme that suits this product idea : ${idea} best) (total 5) (i.e primary, secondary, text color, muted color, neutral color). Add -1 at end of each line. Dont add " " or ' ' or any other character. Only add -1 at end of each line. Generate results like this (primary color: #000000) (secondary color: #000000) (text color: #000000) (muted color: #000000) (neutral color: #000000).`,
            temperature: 0.9,
            max_tokens: 1200,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 1.0,
        });
        // push data to new array and split it by -1 and replace \n with ""
        const colors = response.data.choices[0].text.split("-1").map((item) => item.replace(/\n/g, ""));
        // remove last empty entry
        colors.pop();
        setColors(colors);

        // // get hex values from colors array
        const colorHex = colors.map((item) => item.split(":")[1].trim());
        setcolorHex(colorHex);
    };

    const generateTypography = async () => {
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `We are building a website for a client. We need to generate some ideas for the fonts of the website. Generate Website Font Pairs (total 5) for this Website Idea : ${idea}.Add -1 at end of each line. Dont add "" or '' or any other character. Only add -1 at end of each line. Add the font pair in the format (font1, font2). Add -11 at end of response.`,
            temperature: 0.9,
            max_tokens: 1200,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 1.0,
        });
        // push data to new array and split it by -1 and replace \n with ""
        const typography = response.data.choices[0].text.split("-1").map((item) => item.replace(/\n/g, ""));
        // remove last empty entry
        typography.pop();
        setTypography(typography);
    };

    const generatePlacement = async () => {
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `Generate Website Layout Ideas (total 5) for this Website Idea : ${idea}. Generate user friendly ideas. Add -1 at end of each line. Dont add "" or '' or any other character. Only add -1 at end of each line. Add keyword response_end at end of response.`,
            temperature: 0.9,
            max_tokens: 1200,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 1.0,
            stop: "response_end"
        });
        // push data to new array and split it by -1 and replace \n with ""
        const placement = response.data.choices[0].text.split("-1").map((item) => item.replace(/\n/g, ""));
        // remove last empty entry
        placement.pop();
        setPlacement(placement);
    };

    const generateForm = async () => {
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `Give us best ideas for the form on the website (like what kind of form should be present on ${idea} website. for example contact form, review form etc). Generate Website Form Ideas (total 5) for this Website Idea : ${idea}.Add -1 at end of each line. Dont add "" or '' or any other character. Only add -1 at end of each line.`,
            temperature: 0,
            max_tokens: 1200,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
        });
        // push data to new array and split it by -1 and replace \n with ""
        const form = response.data.choices[0].text.split("-1").map((item) => item.replace(/\n/g, ""));
        // remove last empty entry
        form.pop();
        setForm(form);
    };

    const generateBussinessModel = async () => {
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `Create a slide deck with the following sections:
            1.Company Purpose. Add -1 at end this point.
            2.Problem and current customer solutions. Add -1 at end this point.
            3.Solution, product placement, and use cases. Add -1 at end this point.
            4.Why now, including historical evolution and recent trends. Add -1 at end this point.
            5.Market size, including TAM, SAM, and SOM. Add -1 at end this point.
            6.Competition and competitive advantages. Add -1 at end this point.
            7.Product details and development roadmap. Add -1 at end this point.
            8.Business model, including revenue, pricing, and sales/distribution. Add -1 at end this point.
            9.Team, including founders, management, and advisors. Add -1 at end this point.
            10. Financials. Add -1 at end this point.
            Always generate section for each point. Add -1 at end of each point. Generate Website Bussiness Model for this Website Idea : ${idea} and name of business is ${selectedOption}. Dont add "" or '' or any other character.`,
            temperature: 0.2,
            max_tokens: 3000,
            top_p: 0,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
        });
        // console.log(response.data.choices[0].text);
        // push data to new array and split it by -1 and replace \n with ""
        const bussinessModel = response.data.choices[0].text.split("-1").map((item) => item.replace(/\n/g, ""));
        // remove last empty entry
        bussinessModel.pop();
        setBusiness(bussinessModel);
    };

    const generateProductRequiremtns = async () => {
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `Generate Website Product Requirements for this Website Idea : ${idea}. Use ${selectedOption} as brand name. Add all standrard Product Requirement Document Points and explain each point briefly . 
            Goals : What are the goals of the product? add -1 at end of this paragraph.
            User Personas : Who are the users of the product? add -1 at end of this paragraph.
            User Stories : What are the user stories of the product? add -1 at end of this paragraph.
            Sreens : What are the screens of the product? add -1 at end of each line.
            Functional Requirements : What are the functional requirements of the product? add -1 at end of this paragraph.
            Non-Functional Requirements : What are the non-functional requirements of the product? add -1 at end of this paragraph.
            Other Requirements : What are the other requirements of the product? add -1 at end of this paragraph.
            Explain all these points breifly.
            Dont add line counter. Add -1 at end of each line. Dont add "" or '' or any other character. Only add -1 at end of each line.`,
            temperature: 0.2,
            max_tokens: 3000,
            top_p: 0,
            frequency_penalty: 1.0,
            presence_penalty: 0.0,
        });
        // push data to new array and split it by -1 and replace \n with ""
        const productRequirements = response.data.choices[0].text.split("-1").map((item) => item.replace(/\n/g, ""));
        // remove last empty entry
        productRequirements.pop();
        setProductRequirementsCopy(productRequirements);
    };



    const [setWebsiteNameCopy, websiteNameCopy] = React.useState(false);

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text);
        // set isCopied to true for 1.5 seconds
        if (type === "headings") {
            setHeadingCopy(true);
            setTimeout(() => {
                setHeadingCopy(false);
            }, 1500);
        } else if (type === "websiteName") {
            setWebsiteNameCopy(true);
            setTimeout(() => {
                setWebsiteNameCopy(false);
            }, 1500);
        }
        else if (type === "colors") {
            setColorsCopy(true);
            setTimeout(() => {
                setColorsCopy(false);
            }, 1500);
        }
        else if (type === "typography") {
            setTypographyCopy(true);
            setTimeout(() => {
                setTypographyCopy(false);
            }, 1500);
        }
        else if (type === "placement") {
            setPlacementCopy(true);
            setTimeout(() => {
                setPlacementCopy(false);
            }, 1500);
        }
        else if (type === "form") {
            setFormCopy(true);
            setTimeout(() => {
                setFormCopy(false);
            }, 1500);
        }
    };

    const goToOptions = () => {
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        setAllResults(false);
        setShowOptions(true);
        setShowNameTick(true);
    };

    const goBackToBussinessPlan = () => {
        if(business.length === 0) {
            setShowGenerateDesignBtn(false);
            setShowHeadings(false);
            setShowWebsiteName(false);
            setShowColors(false);
            setShowTypography(false);
            setShowPlacement(false);
            setShowForm(false);
            setShowPrd(false);
            setShowBusinessPlan(false);
            setShowOptions(true);
            setShowRestart(false);
        }
        setShowPrd(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(true);
        setAllResults(false);
        setShowOptions(true);
    };


    const showBusPlan = async() => {
        if(headings.length !== 0 && websiteName.length !== 0 && colors.length !== 0 && typography.length !== 0 && placement.length !== 0 && form.length !== 0) {
            setShowWebDesignTick(true);
        }
        setShowBusinessPlan(false);
        setLoading(false);
        setShowOptions(false);
        setShowRestart(false);
        setShowOptions(false);
        setShowForm(false);
        setShowPlacement(false);
        setShowTypography(false);
        setShowColors(false);
        setShowWebsiteName(false);
        setShowHeadings(false);
        setShowGenerateBusinessPlanBtn(true);
        await generateBussinessModel();
        setShowBusinessPlan(true);
        setAllResults(true);
        setLoading(true);
        setShowGenerateBusinessPlanBtn(false);
        setShowOptions(true);
        setShowBusinessPlan(true);
        setShowBusinessPlanSummary(false)
        setShowBusPlanTick(true);
    };

    const exportAllToPDF = async () => {
        const doc = new jsPDF();

        // add website name
        doc.internal.pageSize.width = 1200;
        doc.setFontSize(20);
        doc.text(20, 20, "Website Name");
        doc.setFontSize(15);
        doc.text(20, 30, selectedOption);
        doc.addPage();

        // add headings
        doc.internal.pageSize.width = 1200;
        doc.setFontSize(20);
        doc.text(20, 20, "Headings");
        doc.setFontSize(15);
        doc.text(20, 30, headings.join("\n"));
        doc.addPage();

        // add colors
        doc.internal.pageSize.width = 1200;
        doc.setFontSize(20);
        doc.text(20, 20, "Colors");
        doc.setFontSize(15);
        doc.text(20, 30, colors.join("\n"));
        doc.addPage();

        // add typography
        doc.internal.pageSize.width = 1200;
        doc.setFontSize(20);
        doc.text(20, 20, "Typography");
        doc.setFontSize(15);
        doc.text(20, 30, typography.join("\n"));
        doc.addPage();

        // add placement
        doc.internal.pageSize.width = 1200;
        doc.setFontSize(20);
        doc.text(20, 20, "Placement");
        doc.setFontSize(15);
        doc.text(20, 30, placement.join("\n"));
        doc.addPage();

        // add form
        doc.internal.pageSize.width = 1200;
        doc.setFontSize(20);
        doc.text(20, 20, "Form");
        doc.setFontSize(15);
        doc.text(20, 30, form.join("\n"));
        doc.addPage();

        // add business plan
        doc.internal.pageSize.width = 1200;
        doc.setFontSize(20);
        doc.text(20, 20, "Business Plan");
        doc.setFontSize(15);
        doc.text(20, 30, business.join("\n"));
        doc.addPage();

        // add product requirements
        doc.internal.pageSize.width = 1200;
        doc.setFontSize(20);
        doc.text(20, 20, "Product Requirements");
        doc.setFontSize(15);
        // if current line has more than 35 words, add a new line
        let productRequirementsString = "";
        productRequirementsCopy.forEach((line) => {
            if (line.split(" ").length > 35) {
                productRequirementsString += line.split(" ").slice(0, 35).join(" ") + "\n";
                productRequirementsString += line.split(" ").slice(35).join(" ") + "\n";
            } else {
                productRequirementsString += line + "\n";
            }
        });
        doc.text(20, 30, productRequirementsString);
        doc.save('website.pdf');
    };

    const onClick = async () => {
        setShowInitialLayout(false);
        setShowOptions(false);
        setAllResults(false);
        setLoading(false);
        await generateWebsiteName();
        setShowWebsiteName(true);
        setAllResults(true);
        setLoading(true);
    };

    const goToWebsiteName = async () => {
        setShowOptions(false);
        setShowHeadings(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        setAllResults(false);
        setLoading(false);
        await generateWebsiteName();
        setShowWebsiteName(true);
        setAllResults(true);
        setLoading(true);
    };

    const goToHeadings = async () => {
        if(business.length !== 0) setShowBusPlanTick(true);
        if(productRequirementsCopy.length !== 0) setShowPrdTick(true);
        setShowOptions(false);
        setShowWebsiteName(false);
        setAllResults(false);
        setLoading(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        await generateHeadings();
        setShowHeadings(true);
        setAllResults(true);
        setLoading(true);
    };

    const goToColors = async () => {
        setShowHeadings(false);
        setAllResults(false);
        setLoading(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        await generateColors();
        setShowColors(true);
        setAllResults(true);
        setLoading(true);
    };

    const goToTypography = async () => {
        setShowColors(false);
        setAllResults(false);
        setLoading(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        await generateTypography();
        setShowTypography(true);
        setAllResults(true);
        setLoading(true);
    };

    const goToPlacement = async () => {
        setShowTypography(false);
        setAllResults(false);
        setLoading(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowForm(false);
        await generatePlacement();
        setShowPlacement(true);
        setAllResults(true);
        setLoading(true);
    };

    const goToForm = async () => {
        setShowPlacement(false);
        setAllResults(false);
        setLoading(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowTypography(false);
        setShowBusinessPlan(false);
        await generateForm();
        setShowForm(true);
        setAllResults(true);
        setLoading(true);
        setShowOptions(true);
        setShowGenerateDesignBtn(false);
        setShowWebDesignTick(true);
    };

    const goBackToWesiteName = () => {
        if(selectedOption === "") return;    
        setShowWebsiteName(false);
        setShowOptions(false);
        setAllResults(false);
        setShowHeadings(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowPrd(false);
        setShowBusinessPlan(false);
        setShowWebsiteName(true);
        setAllResults(true);
        setShowRestart(false);
    };

    const goBackToHeadings = () => {
        if(headings === "") return;
        setShowPrd(false);
        setShowRestart(false);
        setShowHeadings(false);
        setShowOptions(false);
        setAllResults(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        setShowHeadings(true);
        setAllResults(true);
    };

    const goBackToColors = () => {
        setShowColors(false);
        setShowOptions(false);
        setAllResults(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        setShowColors(true);
        setAllResults(true);
    };

    const goBackToTypography = () => {
        setShowTypography(false);
        setShowOptions(false);
        setAllResults(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        setShowTypography(true);
        setAllResults(true);
    };

    const goBackToPlacement = () => {
        setShowPlacement(false);
        setShowOptions(false);
        setAllResults(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowTypography(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        setShowPlacement(true);
        setAllResults(true);
    };

    const goToProduct = () => {
        if(productRequirementsCopy.length === 0) return;
        setShowOptions(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);;
        setShowRestart(true);
        setShowPrd(true);
        setShowPrdTick(true);
    };

    const productBtn = async() => {
        if(business.length !== 0) {
            setShowBusPlanTick(true)
        }
        setShowBusinessPlan(false);
        setShowOptions(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        setShowGenerateDesignBtn(true);
        setLoading(false);
        await generateProductRequiremtns();
        setLoading(true);
        setShowPrd(true);
        setAllResults(true);
        setShowRestart(true);
        setShowPrdTick(true);
    };

    const goToWebsiteDesignSummary = () => {
        if(form.length === 0){
            setShowPrd(false);
            setShowRestart(false);
            setShowHeadings(false);
            setShowBusinessPlan(false);
            setShowOptions(true);
            setShowGenerateDesignBtn(true);
            return
        }
        setShowBusinessPlan(false);
        setShowOptions(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowBusinessPlan(false);
        setShowPrd(false);
        setShowGenerateDesignBtn(true);
        setShowHeadings(true);
        setShowColors(true);
        setShowTypography(true);
        setShowPlacement(true);
        setShowForm(true);
        setShowOptions(true);
        setShowGenerateDesignBtn(false);
    };


    
    const restart = () => {
        setShowPrdTick(false);
        setShowPrd(false);
        setShowBusPlanTick(false);
        setShowNameTick(false);
        setShowWebDesignTick(false);
        setShowBusinessPlan(false);
        setShowOptions(false);
        setShowHeadings(false);
        setShowWebsiteName(false);
        setShowColors(false);
        setShowTypography(false);
        setShowPlacement(false);
        setShowForm(false);
        setShowWebsiteName(false);
        setAllResults(false);
        setWebsiteName([]);
        setHeadings([]);
        setColors([]);
        setTypography([]);
        setPlacement([]);
        setForm([]);
        setIdea("");
        setShowRestart(false);
        setShowInitialLayout(true);
    };

    return (
        <div className='flex flex-col items-center justify-start w-screen min-h-screen bg-[#0a0a0a]'>
            <Header />
            <Spacer y={3} />
            {/* add progress bar to show at which part user is */}
            <div className="flex flex-col md:flex-row w-11/12 items-center justify-center md:w-6/12 md:items-center md:justify-between px-2 gap-y-5 md:gap-x-5">
                {/* create a button that changes its color when active */}
                <button onClick={goBackToWesiteName} className={`flex flex-row items-center justify-center w-24 text-white p-3 rounded-lg ${showWebsiteName ? 'bg-blue-500' : 'bg-transparent'}`}>Name {showNameTick ? <BsCheck className='text-4xl'/> : <RxDotFilled className='text-md'/> }</button>
                {/* website design */}
                <div className='bg-white/40 h-0.5 w-1/3 rounded-2xl'></div>
                <button onClick={goToWebsiteDesignSummary} className={`flex flex-row items-center justify-center w-44 text-white p-3 rounded-lg ${showHeadings || showColors || showForm || showTypography || showPlacement ? 'bg-blue-500' : 'bg-transparent'}`}>Website Design {webDesignTick ? <BsCheck className='text-4xl'/> : <RxDotFilled className='text-2xl'/>} </button>
                <div className='bg-white/40 h-0.5 w-1/3 rounded-2xl'></div>
                {/* Business Model */}
                <button onClick={goBackToBussinessPlan} className={`flex flex-row items-center justify-center w-44 p-3 text-white rounded-lg ${showBusinessPlan ? 'bg-blue-500' : 'bg-transparent'}`}>Business Plan {showBusPlanTick ? <BsCheck className='text-4xl'/> : <RxDotFilled className='text-2xl'/>}</button>
                <div className='bg-white/40 h-0.5 w-1/3 rounded-2xl'></div>
                {/* product requirement */}
                <button onClick={goToProduct} className={`flex flex-row items-center justify-center w-48 text-white p-3 rounded-lg ${showPrd ? 'bg-blue-500' : 'bg-transparent'}`}>Product Requirement {showPrdTick ? <BsCheck className='text-4xl'/> : <RxDotFilled className='text-2xl'/>}</button>
            </div>
            <Spacer y={5} />
            <div id='inputBox' className="flex flex-col w-11/12 lg:w-4/6 items-center justify-center">
                {showInitialLayouyt ? <div className='flex flex-row items-center justify-start lg:w-2/3'>
                    <label className="text-white/80 font-medium text-xl">Explain your idea 🎉</label>
                </div> : null}
                {showInitialLayouyt ?<Spacer x={1} />  : null}
                {showInitialLayouyt ? <textarea rows={12} onChange={onChange} className="w-11/12 lg:w-2/3 p-5 text-gray-800 bg-transparent text-white/80 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" placeholder="I want to build a website for my food delivery business. We deliver food after collecting it from different restaurants. Our specialty is we charge less as compared to our competitors. We believe in consistency and customer value. We want to persuade users coming to our website to buy our service. We love dark colors"></textarea> : null}
                {showInitialLayouyt ? <Spacer y={2} /> : null}
                {showInitialLayouyt ? <div className='flex flex-row items-center justify-center gap-x-5 w-2/3'>
                    <Button onPress={onClick} size={'lg'} auto>Generate</Button>
                </div> : null}
            </div>
            {/* Website name */}
            {showWebsiteName ? <div id='websiteNameDiv' className="flex flex-col w-11/12 md:w-4/6 items-center justify-center">
            <div className='transition flex flex-col items-start w-11/12 lg:w-2/3 bg-[#111111] rounded-xl border border-[#3f3f3f] hover:bg-[#181818] p-5'>
                    <h1 className="font-bold text-2xl text-white">Website Names 🎉</h1>
                    <h5 className="text-white/80 font-normal text-xl">Select the best name for your website.</h5>
                    {websiteName?.map((name) => {
                        return (
                            <div className='flex flex-row items-center justify-start gap-x-2 mb-3'>
                                <input className='w-5 h-5 cursor-pointer' type="radio" value={name} name={name} onChange={handleOptionChange} checked={selectedOption === name} />
                                {selectedOption === name ? <p className="text-blue-500 font-bold text-xl">{name}</p> : <p className="text-white/80 font-normal text-xl">{name}</p>}
                            </div>
                        )
                    })}
                    <Spacer y={2} />
                    {/* custom name option */}
                    <div className='flex flex-row items-center justify-start gap-x-2 mb-3'>
                        <input onClick={resetOption} type='text' className='cursor-pointer bg-white/20 border border-white/60 rounded-xl px-3 py-2 text-white w-56'  placeholder='Enter custom name' onChange={handleCustomNameChange}/>
                    </div>
                    <div className='flex flex-row items-center justify-center gap-x-5'>
                        <Button onClick={generateWebsiteName} auto size={'sm'} className='mt-4'>Generate Again</Button>
                        {selectedOption !== "" ? <Button onPress={goToOptions} auto size={'sm'} className='mt-4'>Next</Button> : null}
                    </div>
                </div>
            </div> : null}
            <div className='flex flex-col items-center justify-center w-11/12 lg:w-4/6 mt-3'>
                {showHeadings ? <div className='transition flex flex-col items-start w-11/12 lg:w-2/3 bg-[#111111] rounded-xl border border-[#3f3f3f] hover:bg-[#181818] p-5'>
                    <h1 className="font-bold text-2xl text-white">Headings ✨</h1>
                    {headings.map((heading) => {
                        return (
                            <h1 className="text-white/80 font-normal text-xl mt-1">{heading}</h1>
                        )
                    })
                    }
                    <div className='flex flex-col md:flex-row items-start justify-between w-full gap-x-5'>
                        <div className='flex flex-row items-center gap-x-5'>
                            <Button onPress={generateHeadings} auto size={'sm'} className='mt-4'>Generate Again</Button>
                            <Button auto bordered size={'sm'} className='mt-4' onClick={() => copyToClipboard(headings, "headings")}>Copy</Button>
                        </div>
                        <div className='flex flex-row items-center gap-x-5'>
                            <Button auto bordered size={'sm'} className='mt-4' onPress={goBackToWesiteName}>👈 Names</Button>
                            <Button auto bordered size={'sm'} className='mt-4' onPress={goToColors}>Colors 👉</Button>
                        </div>
                    </div>
                    {headingCopy ? <p className="text-green-700 bg-green-200 font-normal text-sm mt-3 rounded-xl px-2 py-1">Copied!</p> : null}
                </div> : null}
            </div>
            {/* Color div */}
            <div className='flex flex-col items-center justify-center w-11/12 lg:w-4/6 mt-3'>
                {showColors ? <div className='transition flex flex-col items-start w-11/12 lg:w-2/3 bg-[#111111] rounded-xl border border-[#3f3f3f] hover:bg-[#181818] p-5'>
                    <h1 className="font-bold text-2xl text-white">Colors ✨</h1>
                    {colorHex.map((col) => {
                        return (
                            <div className='flex flex-row items-center justify-start gap-x-2 mb-3'>
                                <div className='w-12 h-12 rounded-full' style={{ backgroundColor: col }}></div>
                                <h1 className="text-white/80 font-normal text-xl mt-1">{col}</h1>
                            </div>
                        )
                    })
                    }
                    <div className='flex flex-col md:flex-row items-start md:items-center justify-start mb-3 md:gap-x-3 gap-y-3'>
                        <input type='text' className='cursor-pointer bg-white/20 border border-white/60 rounded-xl px-3 py-2 w-60 text-white' placeholder='Pastels' onChange={handleCustomColour} />
                        <Button auto bordered size={'sm'} onClick={generateCustomColors}>Generate</Button>
                    </div>
                    <div className='flex flex-col md:flex-row items-start justify-between w-full gap-x-5'>
                        <div className='flex flex-row items-center gap-x-5'>
                            <Button onPress={generateColors} auto size={'sm'} className='mt-4'>Generate Again</Button>
                            <Button auto bordered size={'sm'} className='mt-4' onClick={() => copyToClipboard(colorHex, "colors")}>Copy</Button>
                        </div>
                        <div className='flex flex-row items-center gap-x-5'>
                            <Button auto bordered size={'sm'} className='mt-4' onPress={goBackToHeadings}>👈 Headings</Button>
                            <Button auto bordered size={'sm'} className='mt-4' onPress={goToTypography}>Typography 👉</Button>
                        </div>
                    </div>
                    {colorsCopy ? <p className="text-green-700 bg-green-200 font-normal text-sm mt-3 rounded-xl px-2 py-1">Copied!</p> : null}
                </div> : null}
            </div>
            {/* Typography div */}
            <div className='flex flex-col items-center justify-center w-11/12 lg:w-4/6 mt-3'>
                {showTypography ? <div className='transition flex flex-col items-start w-11/12 lg:w-2/3 bg-[#111111] rounded-xl border border-[#3f3f3f] hover:bg-[#181818] p-5'>
                    <h1 className="font-bold text-2xl text-white">Typography ✨</h1>
                    {typography.map((typo) => {
                        return (
                            <h1 className="text-white/80 font-normal text-xl mt-1">{typo}</h1>
                        )
                    })}
                    <div className='flex flex-col md:flex-row items-start justify-between w-full gap-x-5'>
                        <div className="flex flex-row md:w-full gap-x-5">
                        <Button onPress={generateTypography} auto size={'sm'} className='mt-4'>Generate Again</Button>
                            <Button auto bordered size={'sm'} className='mt-4' onClick={() => copyToClipboard(typography, "typography")}>Copy</Button>
                        </div>
                        <Button auto bordered size={'sm'} onPress={goBackToColors} className='mt-4'>👈 Colors</Button>
                        <Button auto bordered size={'sm'} onPress={goToPlacement} className='mt-4'>Layout 👉</Button>
                    </div>
                    {typographyCopy ? <p className="text-green-700 bg-green-200 font-normal text-sm mt-3 rounded-xl px-2 py-1">Copied!</p> : null}
                </div> : null}
            </div>
            {/* placement div */}
            <div className='flex flex-col items-center justify-center w-11/12 lg:w-4/6 mt-3'>
                {showPlacement ? <div className='transition flex flex-col items-start w-11/12 lg:w-2/3 bg-[#111111] rounded-xl border border-[#3f3f3f] hover:bg-[#181818] p-5'>
                    <h1 className="font-bold text-2xl text-white">Layout ✨</h1>
                    {placement.map((place) => {
                        return (
                            <h1 className="text-white/80 font-normal text-xl mt-1">{place}</h1>
                        )
                    })}
                    <div className='flex flex-col md:flex-row items-start md:items-center justify-between w-full gap-x-5'>
                            <div className="flex flex-row w-full gap-x-5">
                            <Button onPress={generatePlacement} auto size={'sm'} className='mt-4'>Generate Again</Button>
                            <Button auto bordered size={'sm'} className='mt-4' onClick={() => copyToClipboard(placement, "placement")}>Copy</Button>
                        </div>
                        <Button auto bordered size={'sm'} onPress={goBackToTypography} className='mt-4'>👈 Typography</Button>
                        <Button auto bordered size={'sm'} onPress={goToForm} className='mt-4'>Form 👉</Button>
                    </div>
                    {placementCopy ? <p className="text-green-700 bg-green-200 font-normal text-sm mt-3 rounded-xl px-2 py-1">Copied!</p> : null}
                </div> : null}
            </div>
            {/* Form Div */}
            <div className='flex flex-col items-center justify-center w-11/12 lg:w-4/6 mt-3'>
                {showForm ? <div className='transition flex flex-col items-start w-11/12 lg:w-2/3 bg-[#111111] rounded-xl border border-[#3f3f3f] hover:bg-[#181818] p-5'>
                    <h1 className="font-bold text-2xl text-white">Form 📜</h1>
                    {form.map((form) => {
                        return (
                            <h1 className="text-white/80 font-normal text-xl mt-1">{form}</h1>
                        )
                    })}
                    <div className='flex flex-col md:flex-row items-start md:items-center justify-between w-full gap-x-5'>
                        <div className='flex flex-row items-center justify-between w-full'>
                            <div className="flex flex-row w-full gap-x-5">
                                <Button onPress={generateForm} auto size={'sm'} className='mt-4'>Generate Again</Button>
                                <Button auto bordered size={'sm'} className='mt-4' onClick={() => copyToClipboard(form, "form")}>Copy</Button>
                            </div>
                        </div>
                        <Button auto bordered size={'sm'} onPress={goBackToPlacement} className='mt-4'>👈 Layout</Button>
                    </div>
                    {formCopy ? <p className="text-green-700 bg-green-200 font-normal text-sm mt-3 rounded-xl px-2 py-1">Copied!</p> : null}
                </div> : null}
            </div>
            {/* Generate Website Plan Summary */}
            {!setShowBusinessPlanSummary ? <Spacer y={3} /> : null}
            {showForm ? <div className='flex flex-col items-center justify-center w-11/12 md:w-4/6'>
                <Button bordered onPress={goToWebsiteDesignSummary} auto size={'xl'} className='mb-4 mt-6'>Web Design Summary</Button>
            </div> : null}
            {showBusinessPlan ? <div className='flex flex-col items-center justify-center w-11/12 md:w-4/6'>
            <div className='transition flex flex-col items-start w-11/12 lg:w-2/3 bg-[#111111] rounded-xl border border-[#3f3f3f] hover:bg-[#181818] p-5'>
                    <h1 className="font-bold text-xl md:text-2xl text-white">Generate Business Plan 🥳</h1>
                    {business.map((bussiness) => {
                    return (
                        <div className='flex flex-row items-center bg-[#303030] w-full rounded-xl border border-[#474747] mb-4 p-5'>
                            <h1 className="break-normal font-normal text-xl text-white/80">{
                                bussiness
                            }</h1>
                        </div>
                    )
                    })}
                    <div className='flex flex-row w-full gap-x-5'>
                    <Button auto bordered size={'lg'} className='mt-4' onPress={showBusPlan}>Generate Again</Button>
                    </div>
                </div>
            </div> : null}
            {/*  Generate product Requirements Documents */}
            {showPrd ?<div className='flex flex-col items-center justify-center w-11/12 md:w-4/6'>
                <div className='transition flex flex-col items-start w-11/12 lg:w-2/3 bg-[#111111] rounded-xl border border-[#3f3f3f] hover:bg-[#181818] p-5'>
                    <h1 className="font-bold text-2xl text-white">Product Requirements Document 📝</h1>
                    {productRequirementsCopy.map((data) => {
                        return (
                                <h1 className="break-normal font-normal text-xl text-white/80">
                                    {data}
                                </h1>
                        )
                    })}
                    <div className='flex flex-col md:flex-row w-full gap-x-5'>
                        <Button onPress={productBtn} auto bordered size={'lg'} className='mt-4'>Generate Again</Button>
                    </div>
                </div>
            </div> : null}
            {/* Generate Business Plan */}
            <div className='flex flex-col items-center justify-center w-11/12 md:w-4/6'>
                {showOptions ? <div className='flex flex-col items-center w-11/12 lg:w-2/3 mt-5'>
                    <h1 className='text-white/80 text-xl font-normal mt-4 mb-12'>You've selected <span className='text-blue-500 font-bold'>{selectedOption}</span> as your business name. What would you like to do?</h1>
                    <h1 className="font-bold text-2xl text-white">Options 🚀</h1>
                    {showGenerateDesignBtn ? <Button onPress={goToHeadings} auto size={'xl'} className='mb-4 mt-4'>Generate Website Design</Button> : null}
                    {showGenerateBusinessPlanBtn ? <Button size={'xl'} onPress={showBusPlan} className='mb-4'>Generate Business Plan</Button> : null}
                    <Button onPress={productBtn} size={'xl'}>Product Requirement Document</Button>
                </div> : null}
            </div>
            {/* Restart Button */}
            <div className='flex flex-col items-center justify-center w-11/12 md:w-4/6'>
                {showRestart ? <div className='flex flex-row items-center justify-center w-full gap-x-3'>
                    <Button onPress={exportAllToPDF} auto size={'lg'} className='mt-4'>Generate PDF</Button>
                    <Button auto bordered size={'lg'} className='mt-4' onClick={restart}>Restart</Button>
                </div> : null}
            </div>
            <Spacer y={2} />
            {!loading ?
                <div className='flex gap-x-4 flex-row items-center justify-center w-full h-full mb-12'>
                    <p className="text-white/80 font-normal text-xl">Collecting your results. Please Wait</p>
                    <span className='animate-spin text-4xl'>⌛</span>
                </div>
                : null}
        </div>
    )
}

export default Tool