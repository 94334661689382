import {useEffect, useState} from "react";
// 1. import `NextUIProvider` component
import { NextUIProvider } from '@nextui-org/react';
import Home from './pages/Home';
import Tool from './pages/Tool';
import Login from './pages/Login';
import Signup from './pages/Signup';
import { Routes , Route, Navigate } from 'react-router-dom';
import RouteProtection from "./utils/RouteProtection";
import {AuthContext} from "./context";
import {useAuth} from "./hooks/useAuth";


function App() {
  const {userData, loginUserOnStartup} = useAuth();
  useEffect(() => {
    // console.log('loginUserOnStartup called')
    loginUserOnStartup();
  }, []);
  const [authData, setAuthData] = useState({signedIn: userData.signedIn, user: userData.user});

  return (
    <NextUIProvider>
      <AuthContext.Provider value={{authData, setAuthData }}>
        <Routes>
          <Route element={<RouteProtection signedIn={authData.signedIn}/>}>
            <Route exact path="/tool" element={<Tool />} />
          </Route>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </AuthContext.Provider>
    </NextUIProvider>
  );
}

export default App;