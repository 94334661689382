import {useContext, useEffect, useState} from "react";

import {Cookies} from "react-cookie";
import {useLocation, useNavigate} from "react-router-dom";

import {AuthContext} from "../context";
import axios from "axios";

export const useAuth = () => {
    const navigate = useNavigate();
    const { search, pathname } = useLocation();
    let initState = {signedIn: false, user: null}
    const user = JSON.parse(sessionStorage.getItem('user'))
    if(user) {
        initState = {signedIn: true, user}
    }
    if(new URLSearchParams(search).get('token')){
        initState = {signedIn: true, user: {
                name: new URLSearchParams(search).get('name'),
                email: new URLSearchParams(search).get('email'),
                token: new URLSearchParams(search).get('token'),
            }}
    }
    const [userData, setUserdata] = useState(initState);

    const {setAuthData} = useContext(AuthContext);

    useEffect(() => {
        // console.log('useAuth useEffect->',userData)
        setAuthData(userData);
    }, [userData]);

    function getAuthCookieExpiration()
    {
        let date = new Date();
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));  // 7 days
        return date;
    }

    function setAsLogged(user) {

        const cookie = new Cookies();

        cookie.set('is_auth', true, {path: '/', expires: getAuthCookieExpiration(), sameSite: 'lax', httpOnly: false});

        setUserdata({signedIn: true, user});
        sessionStorage.setItem('user',JSON.stringify(user))
        navigate('/');
    }

    function updateLoggedUser(user) {

        setUserdata({signedIn: true, user});
        sessionStorage.setItem('user',JSON.stringify(user))
    }

    function setLogout() {
        const cookie = new Cookies();

        cookie.remove('is_auth', {path: '/', expires: getAuthCookieExpiration(), sameSite: 'lax', httpOnly: false});

        setUserdata({signedIn: false, user: null});
        sessionStorage.removeItem('user')
        navigate('/');
    }

    function loginFromToken(){
        const query = new URLSearchParams(search)
        const user = {
            name: query.get('name'),
            email: query.get('email'),
            token: query.get('token'),
        }
        setAsLogged(user);
    }

    function loginUserOnStartup()
    {
        let token = userData.user?.token;
        if(new URLSearchParams(search).get('token')){
            loginFromToken()
            token = new URLSearchParams(search).get('token')
        }

        const cookie = new Cookies();
        if(cookie.get('is_auth') || token) {

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'no-cors',
            };
            axios.get(`${process.env.REACT_APP_API_URL}/user`,requestOptions)
                .then(handleResponse)
                .then((user) => {
                    if((pathname === '/login' || pathname === '/signup') && token){
                        user.token = token
                        setAsLogged(user);
                    }
                    return user;
                }).catch(error => {
                console.error(error)
                // setUserdata({signedIn: false, user: null});
                setLogout();
            });
            return
            return fetch(`${process.env.REACT_APP_API_URL}/user`, requestOptions)
                .then(handleResponse)
                .then(({data}) => {
                    // console.log('User is Logged',data, pathname)
                    const user = data
                    if((pathname === '/login' || pathname === '/signup') && token){
                        user.token = token
                        setAsLogged(user);
                    }
                    return user;
                }).catch(error => {
                    console.error(error)
                    // setUserdata({signedIn: false, user: null});
                    setLogout();
                });

            // axios.post('/api/me').then(response => {
            //     setAsLogged(response.data.user)
            //     // setUserdata({signedIn: true, user: response.data.user});
            //     // navigate('/');
            // }).catch(error => {
            //     // setUserdata({signedIn: false, user: null});
            //     setLogout();
            // });

        } else {
            // setLogout();
            // setUserdata({signedIn: false, user: null});
            // navigate('/login');
        }
    }

    function handleResponse(response){

        if (response.status !== 200) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                setLogout();
                // window.location.reload(true);
            }

            const error = (response && response.message) || response.statusText;
            console.error(error)
            return Promise.reject(response);
        }
        return response?.data?.data;
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    setLogout();
                    // window.location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                console.error(error)
                return Promise.reject(data);
            }

            return data;
        });
    }

    return {
        userData,
        setAsLogged,
        setLogout,
        loginUserOnStartup,
        updateLoggedUser,
        handleResponse
    }

};
