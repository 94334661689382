import {Navigate, Outlet} from "react-router-dom";

const ProtectedRoute = ({
                            signedIn,
                            redirectPath = '/'
                        }) => {
    if (!signedIn) {
        return <Navigate to={redirectPath} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
