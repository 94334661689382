import React, {useContext} from 'react'
import {Button, Link as NextLink, Spacer} from "@nextui-org/react";
import { Text } from "@nextui-org/react";
import heroImage from '../img/soft_layout.png'
import {useAuth} from "../hooks/useAuth";
import { Header } from "../layout/header";
import { Footer } from "../layout/footer";
import {AuthContext} from "../context";
import {useNavigate} from "react-router-dom";
import {FaFacebookSquare, FaGoogle} from "react-icons/fa";

function Home() {
    const {setLogout} = useAuth();
    const {authData} = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <div className='flex flex-col items-center justify-between w-screen min-h-screen bg-[#0a0a0a]'>
            {
                false &&
                <>
                    <Spacer x={1} />
                    <div className="flex mt-4 flex-row w-full items-center justify-center">
                        <Text h5 color='$white' className='text-3xl text-center lg:text-left mt-3 ml-3'>Welcome {authData?.user?.name} 🥳</Text>
                        <Button size={'sm'} onPress={() => setLogout()} auto>Log out</Button>
                    </div>
                    <Spacer x={1} />
                </>
            }
            {
                authData.signedIn && <Button className="!absolute mt-6 mr-12 self-end"  size={'sm'} auto onPress={() => setLogout()} >Log out</Button>
            }
            {
                !authData.signedIn && <Button className="!absolute mt-6 mr-12 self-end"  size={'sm'} auto onPress={()=>navigate('/login')} >Sign In</Button>
            }
            <Header/>

            <div className="flex flex-col h-full w-11/12 md:w-10/12 items-center justify-center ">
                <div className="flex flex-col lg:flex-row w-11/12 p-4 md:p-12 items-center justify-between">
                    <div className="flex flex-col items-center lg:items-start justify-center md:w-4/6">
                        <Text color='$white' h2 className='text-3xl lg:text-5xl text-center lg:text-left md:w-5/6 mb-8'>You have the idea, we have the tools. Let Znap help you turn your business dream into a reality. 🥳</Text>
                        <Text h5 color='$white' className='text-center lg:text-left opacity-70 md:w-4/6 font-normal text-md md:text-xl mb-8'>We will generate a unique business name, draft a winning business plan, design a stunning website, and develop your product.</Text>

                        {
                            authData.signedIn && <Button onPress={()=>navigate('/tool')} size={'lg'} auto>Get Started 👉</Button>
                        }
                        {
                            !authData.signedIn &&
                            <>
                                <Button onPress={()=>navigate('/signup')} size={'lg'} auto>SIGN UP WITH EMAIL</Button>
                                <Spacer x={1} />
                                <NextLink href={`${process.env.REACT_APP_API_URL}/auth/google`}>
                                    <Button size={'lg'} auto><FaGoogle className='mr-1' /> SIGN UP WITH GOOGLE</Button>
                                </NextLink>
                                <Spacer x={1} />
                                <NextLink href={`${process.env.REACT_APP_API_URL}/auth/facebook`}>
                                    <Button size={'lg'} auto><FaFacebookSquare className='mr-1' /> SIGN UP WITH FACEBOOK</Button>
                                </NextLink>
                            </>
                        }


                    </div>
                    <div className="flex flex-col justify-center mt-12 mb-12 lg:mt-0 lg:w-5/12 h-2/5">
                        <img src={heroImage} className='object-fill mb-5' />
                    </div>
                </div>
            </div>
            {/* Footer */}
            <Footer/>
        </div>
    )
}

export default Home